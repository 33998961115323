import { useEffect, useRef, useState } from 'react'
import { CommissionedOrderType, getCommissionedOrderList2 } from '../../api/swap/klineData'

export function useDepthChartData(id: string) {
  const [data, setData] = useState<CommissionedOrderType>({
    buy: [],
    sell: []
  })
  const timeRef = useRef<any>(null)
  const ignore = useRef(false)
  useEffect(() => {
    ignore.current = false
    const init = async function() {
      try {
        const lists = await getCommissionedOrderList2(id, 200)
        if (ignore.current) {
          return
        }
        setData(lists)
        timeRef.current && clearInterval(timeRef.current)
        timeRef.current = setInterval(() => {
          init()
        }, 10000)
      } catch (e) {
        console.log(e)
      }
    }
    init()
    return () => {
      ignore.current = true
    }
  }, [id])
  useEffect(() => {
    return () => {
      ignore.current = false
      timeRef.current && clearInterval(timeRef.current)
    }
  }, [])
  return {
    data
  }
}
