// import qs from 'qs'
import { callbackTimer } from '../../components/KLineChart/KlineChart2Tools'
import { ChainId, PageTypeParams } from '../common'
import { Time } from 'lightweight-charts'

export interface KlineDataApiDataType {
  created_time: number
  open: number
  high: number
  low: number
  close: number
  volume: number
  market_date: string
}

export const fetchBaseUrl = process.env.REACT_APP_BASE_URL
export const fetchBaseUrl2 = process.env.REACT_APP_BASE_URL2
//  获取 K线数据
export function getKLineData(params: { id: string; type: string; size?: string }): Promise<KlineDataApiDataType[]> {
  return fetch(fetchBaseUrl + '/api/market/marketData', {
    method: 'POST',
    body: JSON.stringify({
      // eslint-disable-next-line @typescript-eslint/camelcase
      dex_pairs_id: params.id,
      type: callbackTimer(params.type),
      size: params.size! || '600'
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return []
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export interface ResponseIncreaseDataType {
  increase: string //涨幅 前端加%
  price: string //价格
  diff: string // 差价
}
// 获取涨幅数据
export function getIncreaseData(params: {
  token: string
  tradeToken: string
  swapVersion: string
}): Promise<ResponseIncreaseDataType> {
  return fetch(fetchBaseUrl + '/api/market/amountOfIncrease', {
    method: 'POST',
    body: JSON.stringify({
      token: params.token.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      trade_token: params.tradeToken.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      swap_version: params.swapVersion.toLowerCase()
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return []
    })
    .catch(error => {
      console.error('Error:', error)
    })
}
export interface ListResultType<T> {
  total: number
  per_page: number
  current_page: number
  last_page: number
  data: T
}
export interface TradeRecordListItemType {
  id: number //id
  buy_order_id?: number //买订单id
  sell_order_id?: number //卖订单id
  pair_id?: number //交易所id
  token0_deal_amount: string //成交数量
  deal_price: string //成交价格
  hash: string //hash
  deal_time: Time //成交时间
  buy_create_time?: Time //买订单时间
  sell_create_time?: Time //卖订单时间
  type: string
  // eslint-disable-next-line @typescript-eslint/camelcase
  sell_user: string
  // eslint-disable-next-line @typescript-eslint/camelcase
  buy_user: string
}
export interface TradeRecordListParams extends PageTypeParams {
  id: string
}
export function getTradeRecordList(params: TradeRecordListParams): Promise<ListResultType<TradeRecordListItemType[]>> {
  return fetch(fetchBaseUrl + '/api/market/latestTransaction', {
    method: 'POST',
    body: JSON.stringify({
      // eslint-disable-next-line @typescript-eslint/camelcase
      dex_pairs_id: params.id,
      page: params.page,
      size: params.size
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return []
    })
    .catch(error => {
      console.error('Error:', error)
    })
}
//  获取24
export interface CoinExType {
  id: number
  token0Symbol: string
  token1Symbol: string
  token0Logo: string
  token1Logo: string
  price: string
  priceDecimals: number
  change_24h: string
  isTrue?: boolean
  info24h: {
    maxPrice: string
    minPrice: string
    vol: string
    volValue: string
  }
  holders: string
  famc: string
}

export function getCoinExTypeData(params: { id: string }): Promise<CoinExType> {
  return fetch(fetchBaseUrl2 + '/system/getAllPairs?id=' + params.id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.length) {
        return data[0]
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export function getLatestMarketOne(params: { id: string; type: string; size?: string }): Promise<KlineDataApiDataType> {
  return fetch(fetchBaseUrl + '/api/market/getLatestMarketPrice', {
    method: 'POST',
    body: JSON.stringify({
      // eslint-disable-next-line @typescript-eslint/camelcase
      dex_pairs_id: params.id,
      type: callbackTimer(params.type)
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
  //   /api/market/latestMarket
}

export interface CoinInfoDetailType {
  chain: string //链id
  chain_name: string //链名称
  release_num: string //发行数量（暂时不用这个字段）
  release_time: Time //发行时间
  name: string //币种名称
  coin_contract: string //合约地址
  intro: string //币种简介
}

// market/coinInfo
export function getCoinInfoDetail(params: { id: string; lang?: string }): Promise<CoinInfoDetailType> {
  return fetch(fetchBaseUrl + '/api/market/getDexPairsToken', {
    method: 'POST',
    body: JSON.stringify({
      // eslint-disable-next-line @typescript-eslint/camelcase
      dex_pairs_id: params.id,
      lang: params.lang || 'zh-cn'
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
  //   /api/market/latestMarket
}

export interface CoinObjectInfoDetail extends CoinInfoDetailType, CoinExType { }

export function judgeTokenHasMarketData(params: {
  token: string
  tradeToken: string
  swapVersion: string
  chainId?: ChainId
}): Promise<number> {
  return fetch(fetchBaseUrl + '/api/market/isMarketData', {
    method: 'POST',
    body: JSON.stringify({
      token: params.token.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      trade_token: params.tradeToken.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      swap_version: params.swapVersion.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      chain_id: params.chainId
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data.is_market_data
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export interface LatestTradeAddressListItem {
  buy_num: number //买数量
  sell_num: number //卖数量
  date_time: string //时间
  time: number | string //时间戳
}
export function getLatestTradeAddressList(params: {
  token: string
  tradeToken: string
  swapVersion: string
}): Promise<LatestTradeAddressListItem[]> {
  return fetch(fetchBaseUrl + '/api/market/latestTradeAddress', {
    method: 'POST',
    body: JSON.stringify({
      token: params.token.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      trade_token: params.tradeToken.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      swap_version: params.swapVersion.toLowerCase()
      // eslint-disable-next-line @typescript-eslint/camelcase
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return []
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export interface CommissionedOrderItemType {
  price: string
  amount: string
  remainAmount: string
}
export interface CommissionedOrderType {
  buy: CommissionedOrderItemType[] //买数量
  sell: CommissionedOrderItemType[] //卖数量
}
export function getCommissionedOrderList(id: string, limit = 20): Promise<CommissionedOrderType> {
  return fetch(fetchBaseUrl2 + '/system/getPriceDepth?id=' + id + '&depth=' + limit, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data && Object.keys(data).length) {
        return data
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export function getCommissionedOrderList2(id: string, limit = 20): Promise<CommissionedOrderType> {
  return fetch(fetchBaseUrl + '/api/index/getPriceDepth?pair_id=' + id + '&limit=' + limit, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data && Object.keys(data).length) {
        return data.data
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export interface MarketEarningExponentType {
  day_increase: string //一天的
  week_increase: string //一个星期的
  month_increase: string //一月的
  three_month_increase: string //三个月的
  year_increase: string //一年的
}
export function getMarketEarningExponent(id: string): Promise<MarketEarningExponentType> {
  return fetch(fetchBaseUrl + '/api/market/moreIncrease', {
    method: 'POST',
    body: JSON.stringify({
      // eslint-disable-next-line @typescript-eslint/camelcase
      pair_id: id
      // token: params.token.toLowerCase(),
      //
      // trade_token: params.tradeToken.toLowerCase(),
      // // eslint-disable-next-line @typescript-eslint/camelcase
      // swap_version: params.swapVersion.toLowerCase()
      // eslint-disable-next-line @typescript-eslint/camelcase
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
}
