import React, { useState } from 'react'
// import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
// useIntroduceViewHook,
import {
  useCommissionedOrderDataViewHook,
  useIntroduceViewHook,
  useQuotesTradeListHook
} from './hook/QuotesTradeListHook'
import QuotesListComponent from './QuotesList'
import { useQuotesActionCoinInfo } from '../../state/quotes/hook'
import { bigNumberTransform, formatMoney, formTimeStamp2DateTime3 } from '../../utils/common'
import { CoinInfoDetailType } from '../../api/swap/klineData'
import { shortenAddress } from '../../utils'
// import CurrencyLogo from '../CurrencyLogo'
// import { bigNumberTransform, formatMoney } from '../../utils/common'
// import { MyCurrency } from '../KLineChart/hook2'
// import { shortenAddress } from '../../utils'
import Copy from '../../components/AccountDetails/Copy'
import { BigNumber } from 'bignumber.js'
import Navs from '../Navs'
import { useDarkModeManager } from '../../state/user/hooks'
// import { BigNumber } from 'bignumber.js'
// // import QuotesDataView from './QuotesDataView'
// import { useQuotesActionCoinInfo } from '../../state/quotes/hook'
// import { CoinInfoDetailType } from '../../api/swap/klineData'
const QuotesTradeListWContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 600px;
  box-sizing: border-box;
  padding-bottom: 40px;
  height: 100%;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`

const QuotesCommissionedTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.border1};
  padding: 10px;
`
const QuotesCommissionedItem = styled.div`
  flex: 1;
  text-align: left;
  color: #909090;
  font-size: 12px;
`
const CommissionedWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`
const CommissionedWrapItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 3px;
  &:last-child {
    margin-right: 0;
  }
`
const CommissionedItem = styled.div<{ color: string; bgColor: string; width: number; position: string }>`
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
  line-height: 40px;
  .label {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.text10};
    z-index: 1;
  }
  .value {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    color: ${({ color }) => color};
    z-index: 1;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    transition: width 0.3s ease-in;
    right: ${({ position }) => {
      if (position === 'right') {
        return 0
      }
      return 'inherit'
    }};
    left: ${({ position }) => {
      if (position === 'left') {
        return 0
      }
      return 'inherit'
    }};
    height: 100%;
    width: ${({ width }) => {
      if (isNaN(width) || !width) {
        return '0%'
      }
      return `${width}%`
    }};
    background-color: ${({ bgColor }) => bgColor};
  }
`

const QuotesTradeListUl = styled.ul`
  color: ${({ theme }) => theme.yellow2};
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  outline-style: none;
  justify-content: space-between;
  margin: 0;
  @media (min-width: 415px) {
    .media-pc {
      max-width: 100%;
    }
  }
`
const QuotesTradeListLi = styled.li<{ textAlign?: string; maxWidth?: string }>`
  color: #909090;
  list-style: none;
  flex: 1;
  font-size: 13px;
  padding: 12px 0;
  white-space: nowrap;
  max-width: ${({ maxWidth }) => {
    if (typeof maxWidth === 'undefined') {
      return '100%'
    }
    return maxWidth
  }};
  text-align: ${({ textAlign }) => {
    if (typeof textAlign === 'undefined') {
      return 'left'
    }
    return textAlign
  }};
`
// const PoolViewContainer = styled.div`
//   padding: 0 10px 10px 10px;
//   margin-top: 10px;
// `
// const PoolViewItem = styled.div`
//   padding: 10px;
//   border-radius: 10px;
//   background-color: ${({ theme }) => theme.bg1};
// `
// const PoolViewHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding-bottom: 10px;
//   border-bottom: 1px solid ${({ theme }) => theme.border1};
//   padding-top: 10px;
// `
// const PoolViewHeaderLeft = styled.div`
//   display: flex;
//   flex-direction: column;
// `
// const PoolViewHeaderRight = styled.div`
//   padding: 10px;
//   border-radius: 10px;
// `
// const PoolViewHeaderTitle = styled.div`
//   font-weight: bold;
//   font-size: 22px;
//   line-height: 25px;
// `
// const PoolViewHeaderDesc = styled.div`
//   font-size: 12px;
//   color: #8d9194;
//   span {
//     color: ${({ theme }) => theme.text1};
//   }
// `
//
// const PoolViewContent = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// `
// const PoolViewContentItem = styled.div`
//   width: 50%;
//   display: flex;
//   flex-direction: column;
//   p {
//     display: flex;
//     align-items: center;
//     font-size: 18px;
//     margin: 5px 0;
//     font-weight: bolder;
//   }
//   em {
//     font-style: normal;
//     margin-left: 8px;
//   }
//   span {
//     font-size: 14px;
//     color: ${({ theme }) => theme.text1};
//   }
// `

const IntroduceViewItem = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.bg1};
  border-bottom: 1px solid ${({ theme }) => theme.border1};
  font-size: 12px;
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  padding: 0 16px 7px;
  p {
    padding: 15px 0;
    margin: 0;
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
  .source {
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 7px;
    img {
      display: block;
      width: 35px;
      height: 35px;
    }
  }
`
// const IntroduceViewBtn = styled.span`
//   display: inline-block;
//   border-radius: 30px;
//   background-color: ${({ theme }) => theme.bg6};
//   padding: 9px 22px;
//   font-size: 10px;
//   text-align: center;
//   margin-right: 7px;
//   margin-bottom: 7px;
// `
//
const IntroduceViewItemLabel = styled.span`
  color: ${({ theme }) => theme.text8};
`
const IntroduceViewItemValue = styled.span`
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  span button {
    padding: 0;
  }
`
// function PoolView() {
//   const { coinInfo } = useQuotesActionCoinInfo()
//   const reserves0 = formatMoney(coinInfo?.reserves0, { decimalPlaces: 0 })
//   const reserves1 = formatMoney(coinInfo?.reserves1, { decimalPlaces: 0 })
//   const price = bigNumberTransform(coinInfo?.price, { decimalPlaces: 6 })
//   const usd = bigNumberTransform(coinInfo?.usd, { decimalPlaces: 6 })
//   return (
//     <PoolViewContainer>
//       <PoolViewItem>
//         <PoolViewHeader>
//           <PoolViewHeaderLeft>
//             <PoolViewHeaderTitle>
//               <small>$</small>
//               {price}
//             </PoolViewHeaderTitle>
//             <PoolViewHeaderDesc>
//               {token.symbol + '：' + tradeToken.symbol}&nbsp;
//               <span>（1：{usd}）</span>
//             </PoolViewHeaderDesc>
//           </PoolViewHeaderLeft>
//           <PoolViewHeaderRight></PoolViewHeaderRight>
//         </PoolViewHeader>
//         <PoolViewContent>
//           <PoolViewContentItem>
//             <p>
//               <CurrencyLogo currency={token} size={'18px'} />
//               <em>{token.symbol}</em>
//             </p>
//             <span>{reserves0}</span>
//           </PoolViewContentItem>
//           <PoolViewContentItem>
//             <p>
//               <CurrencyLogo currency={tradeToken} size={'18px'} />
//               <em>{tradeToken.symbol}</em>
//             </p>
//             <span>{reserves1}</span>
//           </PoolViewContentItem>
//         </PoolViewContent>
//       </PoolViewItem>
//     </PoolViewContainer>
//   )
// }
function CommissionedView({ id }: { id: string }) {
  const [darkMode] = useDarkModeManager()
  const { t } = useTranslation()
  const { data } = useCommissionedOrderDataViewHook(id)
  const { coinInfo } = useQuotesActionCoinInfo()
  return (
    <QuotesTradeListWContainer>
      <QuotesCommissionedTitle>
        <QuotesCommissionedItem>{t('买入')}</QuotesCommissionedItem>
        <QuotesCommissionedItem>{t('卖出')}</QuotesCommissionedItem>
      </QuotesCommissionedTitle>
      <QuotesTradeListUl>
        <QuotesTradeListLi textAlign={'left'}>
          {t('数量')}({coinInfo.token0Symbol})
        </QuotesTradeListLi>
        <QuotesTradeListLi textAlign={'center'}>
          {t('Price')}({coinInfo.token1Symbol})
        </QuotesTradeListLi>
        <QuotesTradeListLi textAlign={'right'}>
          {t('数量')}({coinInfo.token0Symbol})
        </QuotesTradeListLi>
      </QuotesTradeListUl>
      <CommissionedWrap>
        <CommissionedWrapItem>
          {data.buy.map((item, index) => (
            <CommissionedItem
              color={'#36BE00'}
              bgColor={darkMode ? '#22353b' : '#edf8f2'}
              key={index}
              position={'right'}
              width={
                new BigNumber(item.amount).abs().gt(0)
                  ? new BigNumber(item.remainAmount)
                      .dividedBy(new BigNumber(item.amount))
                      .times(100)
                      .toNumber()
                  : 0
              }
            >
              <span className={'label'}>{bigNumberTransform(item.remainAmount, { decimalPlaces: 3 })}</span>
              <span className={'value'}>
                {bigNumberTransform(item.price, {
                  decimalPlaces: coinInfo.priceDecimals > 6 ? 6 : coinInfo.priceDecimals
                })}
              </span>
            </CommissionedItem>
          ))}
        </CommissionedWrapItem>
        <CommissionedWrapItem>
          {data.sell.map((item, index) => (
            <CommissionedItem
              position={'left'}
              color={'#BE0000'}
              bgColor={darkMode ? '#352832' : '#fbecef'}
              key={index}
              width={
                new BigNumber(item.amount).abs().gt(0)
                  ? new BigNumber(item.remainAmount)
                      .dividedBy(new BigNumber(item.amount))
                      .times(100)
                      .toNumber()
                  : 0
              }
            >
              <span className={'value'}>
                {bigNumberTransform(item.price, {
                  decimalPlaces: coinInfo.priceDecimals > 6 ? 6 : coinInfo.priceDecimals
                })}
              </span>
              <span className={'label'}>{bigNumberTransform(item.remainAmount, { decimalPlaces: 3 })}</span>
            </CommissionedItem>
          ))}
        </CommissionedWrapItem>
      </CommissionedWrap>
    </QuotesTradeListWContainer>
  )
}
function TradeView({ id }: { id: string }) {
  const { t } = useTranslation()
  const { lists, loadMoreItems, hasNextPage } = useQuotesTradeListHook(id)
  return (
    <QuotesTradeListWContainer>
      <QuotesTradeListUl>
        <QuotesTradeListLi className="media-pc" maxWidth="55px">
          {t('Time')}
        </QuotesTradeListLi>
        <QuotesTradeListLi className="media-pc" maxWidth="40px" textAlign={'left'}>
          {t('Type')}
        </QuotesTradeListLi>
        <QuotesTradeListLi className="media-pc" maxWidth={'70px'} textAlign={'left'}>
          {t('Price')}($)
        </QuotesTradeListLi>
        <QuotesTradeListLi textAlign={'left'}>{t('Volume')}</QuotesTradeListLi>
        <QuotesTradeListLi textAlign={'right'}>{t('Address')}</QuotesTradeListLi>
      </QuotesTradeListUl>
      <div style={{ flex: '1 1 auto', maxHeight: '500px' }}>
        <QuotesListComponent items={lists} loadMore={loadMoreItems} hasNextPage={hasNextPage}></QuotesListComponent>
      </div>
    </QuotesTradeListWContainer>
  )
}
function IntroduceView({ id }: { id: string }) {
  const { t } = useTranslation()
  const { baseInfo } = useIntroduceViewHook(id) as { baseInfo: CoinInfoDetailType }
  if (!Object.keys(baseInfo).length) {
    return null
  }
  // 总供应量
  return (
    <>
      <IntroduceViewItem>
        <p>{t('币信息')}</p>
        <ul>
          <li>
            <IntroduceViewItemLabel>{t('所属公链')}</IntroduceViewItemLabel>
            <IntroduceViewItemValue>{baseInfo.chain_name}</IntroduceViewItemValue>
          </li>
          <li>
            <IntroduceViewItemLabel>{t('总量')}</IntroduceViewItemLabel>
            <IntroduceViewItemValue>{formatMoney(baseInfo.release_num, { decimalPlaces: 3 })}</IntroduceViewItemValue>
          </li>
          <li>
            <IntroduceViewItemLabel>{t('发行时间')} </IntroduceViewItemLabel>
            <IntroduceViewItemValue>{formTimeStamp2DateTime3(baseInfo.release_time)}</IntroduceViewItemValue>
          </li>
          <li>
            <IntroduceViewItemLabel>{t('智能合约')}</IntroduceViewItemLabel>
            <IntroduceViewItemValue>
              {shortenAddress(baseInfo?.coin_contract as string, 6)}
              &nbsp;&nbsp;
              {baseInfo?.coin_contract ? (
                <Copy isShowBox={true} toCopy={baseInfo?.coin_contract as string} size={'12'}>
                  <span></span>
                </Copy>
              ) : (
                ''
              )}
            </IntroduceViewItemValue>
          </li>
        </ul>
      </IntroduceViewItem>
      {/*<IntroduceViewItem>*/}
      {/*  <p>{t('Tags')}</p>*/}
      {/*  {baseInfo.tags_data &&*/}
      {/*    baseInfo.tags_data.map(item => <IntroduceViewBtn key={item}>#&nbsp;&nbsp;{item}</IntroduceViewBtn>)}*/}
      {/*</IntroduceViewItem>*/}
      <IntroduceViewItem>
        <p>
          {t('关于')}&nbsp;&nbsp;{baseInfo.name || '--'}
        </p>
        <div dangerouslySetInnerHTML={{ __html: baseInfo.intro }}></div>
      </IntroduceViewItem>
      {/*<IntroduceViewItem>*/}
      {/*  <p>{t('资源')}</p>*/}
      {/*  {baseInfo.medium_data &&*/}
      {/*    baseInfo.medium_data.map(item => {*/}
      {/*      return (*/}
      {/*        <a key={item.logo} className={'source'} href={item.url}>*/}
      {/*          <img src={item.logo} />*/}
      {/*        </a>*/}
      {/*      )*/}
      {/*    })}*/}
      {/*</IntroduceViewItem>*/}
    </>
  )
}
export function QuotesTradeList({ id }: { id: string }) {
  const [curState, setCurState] = useState<string>('0')
  return (
    <>
      <Navs isActive={curState} setActiveItem={setCurState}></Navs>
      {curState === '0' ? <CommissionedView id={id}></CommissionedView> : ''}
      {curState === '1' ? <TradeView id={id}></TradeView> : ''}
      {curState === '2' ? <IntroduceView id={id}></IntroduceView> : ''}
    </>
  )
}
//{/*{curState === '3' ? <QuotesDataView token={token} tradeToken={tradeToken}></QuotesDataView> : ''}*/}
