import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  CoinInfoDetailType,
  CommissionedOrderType,
  getCoinInfoDetail,
  getCommissionedOrderList,
  getLatestTradeAddressList,
  getMarketEarningExponent,
  getTradeRecordList,
  LatestTradeAddressListItem,
  ListResultType,
  MarketEarningExponentType,
  TradeRecordListItemType
} from '../../../api/swap/klineData'
import { Currency, PageTypeParams } from '../../../api/common'
import { MyCurrency } from '../../KLineChart/hook2'
import { useCurrentLangManager } from '../../../state/user/hooks'
import emitter from '../../../utils/emitter'

interface UseDefaultChartDataType {
  token: Currency | undefined
  tradeToken: Currency | undefined
}

// 获取 介绍显示
export function useIntroduceViewHook(id: string): { baseInfo: CoinInfoDetailType | {} } {
  const [userLang] = useCurrentLangManager()
  const ignore = useRef(false)
  const [baseInfo, setBaseInfo] = useState<CoinInfoDetailType | {}>({})
  const init = useCallback(
    async function() {
      try {
        const coinInfoDetail: CoinInfoDetailType = await getCoinInfoDetail({
          id,
          lang: userLang === 'zh' ? 'zh-cn' : 'en'
        })
        if (ignore.current) {
          return
        }
        setBaseInfo({
          ...coinInfoDetail
        })
        ignore.current = false
      } catch (e) {
        console.log(e)
      }
    },
    [id, userLang]
  )
  useEffect(() => {
    ignore.current = false
    init()
    return () => {
      ignore.current = true
    }
  }, [id, userLang])
  return {
    baseInfo
  }
}

// 获取 列表数据
export function useQuotesTradeListHook(id: string) {
  const [params, setParams] = useState<PageTypeParams>({
    page: 1,
    size: 30,
    lastPage: 1
  })
  const [lists, setLists] = useState<TradeRecordListItemType[]>([])
  const ignore = useRef(false)
  const [loading, setLoading] = useState(false)

  const fetchLists = useCallback(
    async function() {
      try {
        setLoading(true)
        // eslint-disable-next-line @typescript-eslint/camelcase
        const { data, last_page }: ListResultType<TradeRecordListItemType[]> = await getTradeRecordList({
          ...params,
          id
        })

        if (ignore.current || (data && !Object.keys(data).length) || !data) {
          setLoading(false)
          return
        }
        setParams({
          ...params,
          // eslint-disable-next-line @typescript-eslint/camelcase
          lastPage: last_page
        })
        setLists([...lists].concat(data))
        setLoading(false)
        ignore.current = false
      } catch (e) {
        setLoading(false)
        if (e instanceof Error) {
          console.error(e.message)
        }
      }
    },
    [lists, params, id]
  )

  function loadMore() {
    return new Promise(function(resolve, reject) {
      resolve()
    }).then(function() {
      //模拟ajax请求
      setParams({
        ...params,
        // eslint-disable-next-line @typescript-eslint/camelcase
        page: params.page + 1
      })
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const loadMoreItems = loading ? () => {} : loadMore
  useEffect(() => {
    ignore.current = false
    fetchLists()
    return () => {
      ignore.current = true
    }
  }, [params.page])

  const handleTradeLists = useCallback(
    tradeData => {
      const targetTradeData = [...lists]
      targetTradeData.unshift(tradeData)
      setLists(targetTradeData)
    },
    [lists]
  )
  useEffect(() => {
    emitter.on('handleTradeLists', handleTradeLists)
  }, [lists])

  useEffect(() => {
    return () => emitter.off('handleTradeLists', handleTradeLists)
  }, [])
  const hasNextPage: boolean = useMemo(() => {
    return params.page < params.lastPage
  }, [params])

  return { lists, params, loadMoreItems, loading, hasNextPage }
}

export function useQuotesDataViewHook({ token, tradeToken }: UseDefaultChartDataType) {
  const [data, setData] = useState<LatestTradeAddressListItem[]>([])
  const ignore = useRef(false)
  useEffect(() => {
    ignore.current = false
    const init = async function() {
      try {
        const MyToken = token as MyCurrency
        const TradeToken = tradeToken as MyCurrency
        const lists = await getLatestTradeAddressList({
          token: (MyToken && MyToken.address) as string,
          tradeToken: (TradeToken && TradeToken.address) as string,
          swapVersion: MyToken && MyToken.router!
        })
        if (ignore.current || !lists || !lists.length) {
          return
        }
        setData(lists)
      } catch (e) {
        console.log(e)
      }
    }
    init()
    return () => {
      ignore.current = true
    }
  }, [token, tradeToken])
  return { data }
}

export function useCommissionedOrderDataViewHook(id: string) {
  const [data, setData] = useState<CommissionedOrderType>({
    buy: [],
    sell: []
  })
  const timeRef = useRef<any>(null)
  const ignore = useRef(false)
  useEffect(() => {
    ignore.current = false
    const init = async function() {
      try {
        const lists = await getCommissionedOrderList(id)
        if (ignore.current) {
          return
        }
        setData(lists)
        timeRef.current && clearInterval(timeRef.current)
        timeRef.current = setInterval(() => {
          init()
        }, 10000)
      } catch (e) {
        console.log(e)
      }
    }
    init()
    return () => {
      ignore.current = true
      timeRef.current && clearInterval(timeRef.current)
    }
  }, [id])
  return { data }
}

export function useIncomeIndexHook(id: string) {
  const [data, setData] = useState<MarketEarningExponentType>({
    // eslint-disable-next-line @typescript-eslint/camelcase
    day_increase: '--', //一天的
    // eslint-disable-next-line @typescript-eslint/camelcase
    week_increase: '--', //一个星期的
    // eslint-disable-next-line @typescript-eslint/camelcase
    month_increase: '--', //一月的
    // eslint-disable-next-line @typescript-eslint/camelcase
    three_month_increase: '--', //三个月的
    // eslint-disable-next-line @typescript-eslint/camelcase
    year_increase: '--' //一年的
  })
  const ignore = useRef(false)
  const timer = useRef<any>(null)
  useEffect(() => {
    timer.current && clearInterval(timer.current)
    ignore.current = false
    const init = async function() {
      try {
        const result = await getMarketEarningExponent(id)
        timer.current && clearInterval(timer.current)
        if (ignore.current || !Object.keys(result).length) {
          return
        }
        setData(result)
        timer.current = setInterval(() => {
          init()
        }, 10000)
      } catch (e) {
        console.log(e)
      }
    }
    init()
    return () => {
      timer.current && clearInterval(timer.current)
      ignore.current = true
    }
  }, [id])
  return { data }
}
